import './scripts-bs4/ajaxTabs'
import modal from './eproc/modal'

(function($, document, window) {
    $(document).ready(function() {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover({
            placement: 'right',
            trigger: 'hover focus',
            fallbackPlacement: ['right', 'top', 'left', 'bottom'],
        })

        //mobile-search
        $('#search-toggle').click(function(e) {
            setTimeout(function() { $('input[name=txtNumProcessoPesquisaRapida]').focus() }, 500)
        })

        $('#search-close').click(function(e) {
            e.preventDefault()
            $('#mobile-search').toggleClass('mobile-search-visible mobile-search-invisible')
        })

        //search-filter
        $('.search-panel .dropdown-menu').find('a').click(function(e) {
            e.preventDefault()
            var param = $(this).attr('href').replace('#', '')
            var concept = $(this).text()
            $('.search-panel span#search_concept').text(concept)
            $('.input-group #search_param').val(param)
        })

        //#vermaismenos
        if ($('#collapseSummary').height() < 105) {
            $('#divVerMaisMenos').attr('style', 'display: none !important')
        }

        //botões-dropdown dentro da uma linha clicável de tabela
        $('a.table-btn').click(function(e) {
            e.stopPropagation()
            $(this).next('.dropdown-menu').toggle()
        })

        //botões dropdown dentro de uma table-responsive
        $('.table-responsive').on('show.bs.dropdown', function() {
            $('.table-responsive').css('overflow', 'inherit')
        })
        $('.table-responsive').on('hide.bs.dropdown', function() {
            $('.table-responsive').css('overflow', 'auto')
        })

        $('#mostrar-btn-padrão').click(function() {
            $('.btn-escolher-padrao').toggleClass('d-none d-block')
        })

        //collapse - expandir/recolher todos
        function expandAll() {
            $('.collapsible-header').addClass('active')
            $('.collapsible').collapsible({accordion: false})
        }

        function collapseAll() {
            $('.collapsible-header').removeClass(function() {
                return 'active'
            })
            $('.collapsible').collapsible({accordion: true})
            $('.collapsible').collapsible({accordion: false})
        }

        //stop-propagation class
        $('.stop-propagation').on('click', function(e) {
            e.stopPropagation()
        })

        //swicthes -show/hide target
        $('.switch-toggle').change(function() {
            const TARGET = '#' + $(this).attr('data-target')
            const IS_CHECKED = $(this).is(':checked')
            IS_CHECKED ? $(TARGET).show() : $(TARGET).hide()
            const NAME = $(this).attr('id')
            const DATE = new Date()
            const SEIS_MESES = 6 * 30 * 24 * 60 * 60 * 1000
            DATE.setTime(DATE.getTime() + SEIS_MESES)
            const EXPIRES = 'expires=' + DATE.toUTCString()
            const COOKIE = NAME + '=' + IS_CHECKED + ';' + EXPIRES
            document.cookie = COOKIE
        })

        modal.init('.modal')

    })

    window.showReadMore = showReadMore
})($, document, window)

$(document).ready(function() {
    $('.floatingButton').on(
        'click',
        function(e) {
            e.preventDefault()
            $(this).toggleClass('open')
            if ($(this).children('.fa').hasClass('fa-plus')) {
                $(this).children('.fa').removeClass('fa-plus')
                $(this).children('.fa').addClass('fa-close')
            } else if ($(this).children('.fa').hasClass('fa-close')) {
                $(this).children('.fa').removeClass('fa-close')
                $(this).children('.fa').addClass('fa-plus')
            }
            $('.floatingMenu').stop().slideToggle()
        },
    )
    $(this).on('click', function(e) {
        var container = $('.floatingButton')

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && $('.floatingButtonWrap').has(e.target).length === 0) {
            if (container.hasClass('open')) {
                container.removeClass('open')
            }
            if (container.children('.fa').hasClass('fa-close')) {
                container.children('.fa').removeClass('fa-close')
                container.children('.fa').addClass('fa-plus')
            }
            $('.floatingMenu').hide()
        }
    })
})

$(document).ready(function() {
    $('.switch-toggle').change(function() {
        var target = $(this).attr('data-target')
        if ($(this).is(':checked')) {
            $('#' + target).show()
        } else {
            $('#' + target).hide()
        }
    })
})

//vermaisvermenos componente
$(document).on('click', '.btn-read-more', function() {
    var btn = $(this)
    var target = btn.parent('div').prev('.show-read-more')
    var divCollapsedHeight = target.attr('read-more-collapsed-height')
    var divHeight = target.attr('read-more-height')

    if (btn.text() == '+ Ver mais') {
        btn.text('- Ver menos')
        showReadMoreText(target, divHeight)
    } else {
        btn.text('+ Ver mais')
        hideReadMoreText(target, divCollapsedHeight)
    }
})

function showReadMore() {
    var btnReadMore = '<div class="wrapper-btn-read-more"><a href="#/" class="btn-read-more">+ Ver mais</a></div>'

    $('.show-read-more').each(function() {
        var el = $(this)
        var linesLimit = 3

        el.next('.wrapper-btn-read-more').remove()

        el.css('height', 'auto')
        el.attr('id', 'srm')

        if (el.attr('lines-limit')) {
            linesLimit = el.attr('lines-limit')
        }

        var divHeight = parseInt(el.css('height'), 10)
        el.attr('read-more-height', divHeight)

        var lineHeight = parseInt(el.css('line-height'), 10)
        var divMaxHeight = lineHeight * linesLimit
        el.attr('read-more-collapsed-height', divMaxHeight)

        if (divHeight > divMaxHeight) {
            hideReadMoreText(el, divMaxHeight)
            el.after(btnReadMore)
        }
    })
}

function hideReadMoreText(el, divCollapsedHeight) {
    el.css('height', divCollapsedHeight)
}

function showReadMoreText(el, divHeight) {
    el.css('height', divHeight)
}

$(document).ready(function() {
    showReadMore()
})

$(window).resize(function() {
    showReadMore()
})