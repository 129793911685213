export default {
    init: (id, feedbackContainerHtml) => {
        const select = $('#' + id);
        select.selectpicker();
        select.parent().append(feedbackContainerHtml);
    },

    /**
     *
     * @param dependencyName
     * @param inputId
     * @param spinnerPlaceholder
     * @param childId
     * @param callbackMethod
     * @param url
     * @param paramsFn
     * @param {string|null} placeholder
     * @param placeholderIfNull
     * @param valueAttrFn
     * @param innerHTMLAttrFn
     * @param dataMap
     * @param shouldTriggerSelectOnChange
     */
    addDependency: (dependencyName,
                    inputId,
                    spinnerPlaceholder,
                    childId,
                    callbackMethod,
                    url,
                    paramsFn,
                    placeholder,
                    placeholderIfNull,
                    valueAttrFn,
                    innerHTMLAttrFn,
                    dataMap,
                    shouldTriggerSelectOnChange) => {

        const jqInput = $('#' + inputId)

        //todo formatar o resultado (nao posso garantir que vai ser sempre innerHTML, value e data

        //todo quem gera isso é o pai né... assim vai ter duplicatas no caso de mais de um elemento dependente
        function getSelectedOptionData(el) {
            var selectedOption = el.selectedOptions[0];

            return {
                innerHTML: selectedOption.innerHTML,
                value: selectedOption.value,
                data: selectedOption.dataset
            };
        }

        function addOptionsToSelect(placeholder, select, setOptions, dataMap, valueAttr, innerHTMLAttr) {
            let optionsHTML = ''

            if (placeholder !== null) {
                optionsHTML = `<option value=''>${placeholder}</option>`;
            }

            setOptions.forEach(function (v, k) {
                const value = valueAttr(k, v)
                const innerHTML = innerHTMLAttr(k, v)
                const optionAttrsString = Object.getOwnPropertyNames(dataMap)
                    .map((returnAttr, optionAttr) => `${optionAttr}="${o[returnAttr]}"`)
                    .join(' ')

                optionsHTML += `<option value="${value}" ${optionAttrsString}>${innerHTML}</option>`
            });

            select.innerHTML = optionsHTML
            $("#" + select.id).selectpicker('refresh')
        }

        function setChildSelectDisabled(placeholder, child) {
            child.disabled = true;
            addOptionsToSelect(placeholder, child, []);
        }

        function clearSelect(el) {
            el.disabled = true;
            el.innerHTML = '';
            $("#" + el.id).selectpicker('refresh');
            $("#" + el.id).trigger('change')
        }

        function showSpinner(spinner) {
            spinner.style = '';
        }

        function hideSpinner(spinner) {
            spinner.style = 'display:none;';
        }

        function clearFeedbackMessage(feedbackMessage) {
            feedbackMessage.html('');
            feedbackMessage.hide();
        }

        function setFeedbackMessage(feedbackMessage, message) {
            feedbackMessage.html(message);
            feedbackMessage.show();
        }

        jqInput.change(function () {
            const select = this;

            const childEl = document.getElementById(childId);
            const feedbackMessage = $('#' + childEl.id + '-feedbackMessage')

            clearSelect(childEl);
            clearFeedbackMessage(feedbackMessage);

            const spinner = document.getElementById(spinnerPlaceholder)

            if (select.value) {

                showSpinner(spinner);

                axios[callbackMethod](url, paramsFn())
                    .then(function (data) {
                        childEl.disabled = false;
                        addOptionsToSelect(placeholder, childEl, data.data, dataMap, valueAttrFn, innerHTMLAttrFn);
                        if (placeholder === null) {
                            $(childEl).trigger('change');
                        }
                    })
                    .catch(error => {
                        setFeedbackMessage(feedbackMessage, error);
                        console.error(error);
                    })
                    .finally(() => {
                        hideSpinner(spinner);
                    });

            } else {
                setChildSelectDisabled(placeholderIfNull, childEl);
            }
        });

        if (shouldTriggerSelectOnChange) {
            jqInput.change();
        }
    },
}
