export default {
    init(startId, endId, withTime) {
        UI.PHPHelper.date.init(startId, withTime);
        UI.PHPHelper.date.init(endId, withTime);

        const dateStart = $('#' + startId);
        const dateEnd = $('#' + endId);

        dateStart.on("change.datetimepicker", function (e) {
            const minDate = dateStart.val() ? e.date : false;
            dateEnd.datetimepicker('minDate', minDate);
        });

        dateEnd.on("change.datetimepicker", function (e) {
            const maxDate = dateEnd.val() ? e.date : false;
            dateStart.datetimepicker('maxDate', maxDate);
        });
    }
}
