import {DATA_ATTRIBUTES, debounce, isObject} from "../utils"
import MultiAutocomplete from "../components/MultiAutocomplete"
import Autocomplete from "../components/Autocomplete"


export function reformatWhitelist(dataSource, whitelist) {
    let newWhitelist = [];

    if (isObject(whitelist)) { // k => v map
        for (const [k, v] of Object.entries(whitelist)) {
            newWhitelist.push({
                key: k,
                isObject: true,
                value: dataSource.labelFormatFn(k, v), //used by Tagify exclusively
                data: v
            });
        }
    } else { //array of strings OR array of objects
        whitelist.forEach((v, k) => {
            newWhitelist.push({
                key: k,
                isObject: false,
                value: dataSource.labelFormatFn(k, v),
                data: v
            });
        });
    }
    return newWhitelist;
}

export default {
    init(id, tags, inputNamePrefix, isMultiple, dataSource, minChars) {


        addValidationHandlers(id);

        let idTags = id,
            input = document.getElementById(idTags),
            idSelectAll = id + '-listAll',
            controller,
            newTags = null,
            sourceUrl = dataSource.url

        if (tags) {
            newTags = reformatWhitelist(dataSource, tags);
        }

        let tagifyOptions = {
            enforceWhitelist: true,
            whitelist: newTags,
            delimiters: null,
            editTags: 0,
            addTagOnBlur: false,
            templates: {
                tag(tagData) {
                    const inputs = createInputElementsFromTagData(tagData);

                    return `<tag title="${(tagData.title || tagData.value)}"
                          contenteditable='false'
                          spellcheck='false'
                          tabIndex="0"
                          class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
                          ${this.getAttributes(tagData)}>
                            <button type="button" title='Remover' class="p-2 btn btn-link ${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></button>
                            <div>
                            <span class="${this.settings.classNames.tagText}">${(tagData.title || tagData.value)}</span>
                            </div>
                            ${inputs}
                        </tag>`
                }
            },
            dropdown: {
                maxItems: Infinity,
                classname: "color-blue",
                enabled: minChars
            }
        }


        if (!isMultiple) {
            tagifyOptions.mode = 'select';
        }

        const tagify = new Tagify(input, tagifyOptions);

        createAutocompleteObject(isMultiple, id, dataSource, tagify, input)

        tagify.on('blur', (e) => {
            return false;
        });
        tagify.on('input', resetWhitelist);
        tagify.on('input', debounce(onInput, 400));

        $("#" + idSelectAll).click(function () {
            tagify.DOM.input.innerHTML = '';
            doFetch('', true);
        });

        if (newTags) {
            tagify.addTags(newTags);
        }

        function onInput(e) {
            var value = e.detail.value;
            if (value.length >= minChars) {
                doFetch(value, false);
            }
        }

        function resetWhitelist() {
            tagify.whitelist = null // reset the whitelist
            tagify.dropdown.refilter()
            tagify.dropdown.hide(true)
        }


        function doFetch(value, fetchAll) {

            resetWhitelist();

            // show loading animation and hide the suggestions dropdown
            if (value === '' && fetchAll === false) {
                tagify.loading(false)
                return
            }

            const {valueString, options} = getFetchParams(value)

            tagify.loading(true);

            UI.fetch(
                sourceUrl + valueString,
                options,
                async function (res) {
                    const whitelist = await res.json()
                    tagify.whitelist = reformatWhitelist(dataSource, whitelist);
                    tagify
                        .loading(false)
                        .dropdown.show(null)
                })


        }

        function getFetchParams(value) {

            controller && controller.abort();
            // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
            controller = new AbortController();


            let options = {
                method: dataSource.method,
                signal: controller.signal
            }

            let valueString = '';
            if (dataSource.method === 'post') {
                //options.headers = {'Content-Type': 'application/x-www-form-urlencoded'};
                let params = new URLSearchParams();
                params.set('q', value);
                options.body = params;
            } else {
                valueString = value !== null ? ((sourceUrl.indexOf('?') !== -1 ? '&' : '?') + 'q=' + value) : '';
            }

            return {valueString, options}
        }

        function createInputElementsFromTagData(tagData) {
            var inputKey = dataSource.valueFormatFn(tagData.key, tagData.data);

            let inputs;
            const data = tagData.data;
            if (tagData.isObject === true) {
                inputs = createInput(`${inputNamePrefix}_map[${tagData.key}]`, tagData.value);
            } else if (isObject(data)) {
                inputs = Object.entries(data)
                    .map(v => createInput(`${inputNamePrefix}[${inputKey}][${v[0]}]`, v[1]))
                    .join(' ');
            } else { //string
                inputs = createInput(`${inputNamePrefix}[${data}]`, '');
            }

            return inputs;

        }

        function createInput(name, value) {
            return `<input type="hidden" name="${name}" value="${value}"/>\n`;
        }

        function addValidationHandlers(id) {
            document.getElementById(id).addEventListener('invalid', function () {
                this.previousSibling.classList.remove('is-valid');
                this.previousSibling.classList.add('is-invalid');
            })

            document.getElementById(id).addEventListener('change', function () {
                if (this.checkValidity()) {
                    this.previousSibling.classList.add('is-valid');
                    this.previousSibling.classList.remove('is-invalid');
                }
            })
        }


        // bind "DragSort" to Tagify's main element and tell
        // it that all the items with the below "selector" are "draggable"
        var dragsort = new DragSort(tagify.DOM.scope, {
            selector: '.' + tagify.settings.classNames.tag,
            callbacks: {
                dragEnd: onDragEnd
            }
        })

        // must update Tagify's value according to the re-ordered nodes in the DOM
        function onDragEnd(elm) {
            tagify.updateValueByDOMTags();
        }

    },
    defaultLabelInnerHtmlFormat: function (k, v) {
        return isObject(v) ? Object.values(v).join(' - ') : v;
    },
    defaultValueFormat: function (k, v) {
        return k ? k : (isObject(v) ? null : v);
    }

}

function createAutocompleteObject(isMultiple, id, dataSource, tagify, input) {
    let dataAttribute, object
    if (isMultiple) {
        object = new MultiAutocomplete(id, dataSource, tagify)
        dataAttribute = DATA_ATTRIBUTES.multiAutocomplete
    } else {
        object = new Autocomplete(id, dataSource, tagify)
        dataAttribute = DATA_ATTRIBUTES.autocomplete
    }
    $(input).data(dataAttribute, object)
}

