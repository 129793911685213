"use strict";

import autocomplete from './phphelpers/autocomplete';
import dateInterval from './phphelpers/dateInterval';
import date from './phphelpers/date';
import time from './phphelpers/time';
import select from './phphelpers/select';
import addActivatedBy from './phphelpers/addActivatedBy';
import {DATA_ATTRIBUTES} from "./utils";


export const UI = {
    defaults: {
        locale: 'pt-br',
        dateHourMinFormat: 'DD/MM/YYYY HH:mm',
        dateFormat: 'DD/MM/YYYY',
        hourMinFormat: 'HH:mm',
        /**
         * @param {Response} response
         */
        fetchErrorHandler: (response) => {
            throw Error(response.statusText)
        }
    },
    PHPHelper: {
        autocomplete: autocomplete,
        date: date,
        dateInterval: dateInterval,
        select: select,
        time: time,
        addActivatedBy: addActivatedBy,
    },
    multiAutocomplete: {
        /**
         *
         * @param id
         * @returns MultiAutocomplete
         */
        get: (id) => {
            return $("#" + id).data(DATA_ATTRIBUTES.multiAutocomplete)
        }
    },

    autocomplete: {
        /**
         *
         * @param id
         * @returns Autocomplete
         */
        get: (id) => {
            return $("#" + id).data(DATA_ATTRIBUTES.autocomplete)
        }
    },
    /**
     *
     * @param {string} url
     * @param {Object} options
     * @param userCallbacks
     */
    fetch: function (url, options, userCallbacks) {
        /**
         * @param {Response} response
         * @returns {{ok}|*}
         */
        function tryToCatchErrors(response) {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response
        }

        try {
            return fetch(url, options)
                .then(tryToCatchErrors)
                .then(userCallbacks)
                .catch(UI.defaults.fetchErrorHandler)
        } catch (err) {
            console.error(err)
            throw(err)
        }

    }
}

export default UI
